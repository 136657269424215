import { StatusCodes } from 'http-status-codes';

export enum HTTPError {
  GENERAL_ERROR = StatusCodes.INTERNAL_SERVER_ERROR,
  NOT_IMPLEMENTED_ERROR = StatusCodes.NOT_IMPLEMENTED,
  MISSING_SETTINGS = 505,
  FEATURE_NOT_SUPPORTED_ERROR = 585,

  REFUND_SESSION_OTHER_USER_ERROR = 551,
  REFUND_CONNECTION_ERROR = 552,
  CONCUR_CITY_UNKNOWN_ERROR = 553,
  CANNOT_DELETED_REFUNDED_SESSION = 555,

  PRICING_REQUEST_INVOICE_ERROR = 568,

  CYPHER_INVALID_SENSITIVE_DATA_ERROR = 550,

  CHARGING_STATION_DELETE_WITH_TRANSACTIONS_ERROR = 570,

  PRICING_DEFINITION_WITH_SUBSCRIPTION_ERROR = 557,
  SUBSCRIPTION_WITH_USER_SUBSCRIPTION_ERROR = 558,

  SET_CHARGING_PROFILE_ERROR = 554,
  LIMIT_POWER_ERROR = 556,

  USER_EMAIL_ALREADY_EXIST_ERROR = 510,
  USER_EMAIL_DOMAIN_NAME_BLOCKED = 511,
  USER_EULA_ERROR = 520,
  USER_PRIVACY_ERROR = 521,
  USER_DELETE_WITH_TRANSACTIONS_ERROR = 530,
  USER_NO_BADGE_ERROR = 578,

  TAG_ALREADY_EXIST_ERROR = 560,
  TAG_VISUAL_ID_ALREADY_EXIST_ERROR = 561,
  TAG_VISUAL_ID_DOES_NOT_MATCH_TAG_ERROR = 562,
  TAG_INACTIVE = 563,

  USER_ACCOUNT_LOCKED_ERROR = 590,
  USER_ACCOUNT_ALREADY_ACTIVE_ERROR = 591,
  USER_ACCOUNT_PENDING_ERROR = 592,
  USER_ACCOUNT_BLOCKED_ERROR = 593,
  USER_ACCOUNT_INACTIVE_ERROR = 594,
  USER_ACCOUNT_CHANGED = 595,
  TENANT_COMPONENT_CHANGED = 596,
  TENANT_ALREADY_EXIST = 597,
  TECHNICAL_USER_CANNOT_LOG_TO_UI_ERROR = 598,

  GUEST_USER_EXISTS_IN_APP_ERROR = 540,
  GUEST_USER_SESSION_IN_PROGRESS_ERROR = 541,
  GUEST_USER_CHARGING_STATION_DOES_NOT_EXIST_ERROR = 542,
  GUEST_USER_CHARGING_STATION_CONNECTOR_DOES_NOT_EXIST_ERROR = 543,
  GUEST_USER_CHARGING_STATION_CONNECTOR_STATUS_ERROR = 544,
  GUEST_USER_INVALID_TOKEN_ERROR = 545,
  GUEST_BILLING_NOT_SETUP = 546,

  INVALID_CAPTCHA = 531,
  INVALID_TOKEN_ERROR = 532,
  CHARGER_WITH_NO_SITE_AREA_ERROR = 525,
  SITE_AREA_WITH_NO_SITE_ERROR = 526,
  USER_WITH_NO_SITE_ERROR = 527,

  BILLING_DELETE_ERROR = 533,
  BILLING_CURRENCY_NOT_SET_ERROR = 534,

  CLEAR_CHARGING_PROFILE_NOT_SUCCESSFUL = 535,
  SMART_CHARGING_STILL_ACTIVE_FOR_SITE_AREA = 536,
  CHARGING_PROFILE_ALREADY_EXISTS = 537,
  CHARGING_PROFILE_DOES_NOT_EXIST = 538,

  CAR_ALREADY_EXIST_ERROR = 564,
  USER_ALREADY_ASSIGNED_TO_CAR = 565,
  USER_NOT_OWNER_OF_THE_CAR = 566,

  TRANSACTION_NOT_FROM_TENANT = 582,
  TRANSACTION_WITH_NO_ROAMING_DATA = 583,
  TRANSACTION_CDR_ALREADY_PUSHED = 584,

  THREE_PHASE_CHARGER_ON_SINGLE_PHASE_SITE_AREA = 539,

  CRYPTO_ALGORITHM_NOT_SUPPORTED = 512,
  CRYPTO_KEY_LENGTH_INVALID = 513,
  CRYPTO_CHECK_FAILED = 514,
  CRYPTO_MIGRATION_IN_PROGRESS = 515,

  CANNOT_ACQUIRE_LOCK = 516,
  ASYNC_TASK_ALREADY_CREATED = 517,
  INVALID_FILE_FORMAT = 518,
  INVALID_FILE_CSV_HEADER_FORMAT = 519,

  CHARGE_POINT_NOT_VALID = 528,

  OCPI_ENDPOINT_ALREADY_REGISTERED = 547,
  OCPI_ENDPOINT_ALREADY_UNREGISTERED = 548,

  SITE_AREA_TREE_ERROR = 571,
  SITE_AREA_TREE_ERROR_SMART_SAME_SITE_AREA = 572,
  SITE_AREA_TREE_ERROR_SITE = 573,
  SITE_AREA_TREE_ERROR_SMART_CHARGING = 574,
  SITE_AREA_TREE_ERROR_SMART_NBR_PHASES = 575,
  SITE_AREA_TREE_ERROR_VOLTAGE = 576,
  SITE_AREA_TREE_ERROR_MULTIPLE_ACTIONS_NOT_SUPPORTED = 577,

  CANNOT_RETRIEVE_CONSUMPTION = 581,

  CHARGING_STATION_TEMPLATE_IS_INVALID_ERROR = 586,
}
