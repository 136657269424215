import { Pipe, PipeTransform } from '@angular/core';

import { Utils } from '../../utils/Utils';

@Pipe({ name: 'appConnectorID' })
export class AppConnectorIdPipe implements PipeTransform {

  public transform(connectorID: number, evseID: number): string {
    return Utils.getConnectorLetterFromConnectorIDEvseID(connectorID, evseID);
  }
}
