import { ChargingStation } from '../types/ChargingStation';
import { ChargingProfileAuthorizationActions, DialogData } from './Authorization';
import { OCPPChargingRateUnit } from './ocpp/OCPPCommon';
import { SiteArea } from './SiteArea';
import { TableData } from './Table';

export interface ChargingStationChargingProfile extends ChargingProfileAuthorizationActions, TableData, DialogData {
  id: string;
  chargingStationID: string;
  chargingStation?: ChargingStation;
  connectorID?: number;
  evseID?: number;
  companyID?: string;
  siteID?: string;
  siteAreaID?: string;
  siteArea?: SiteArea;
  source?: ChargingProfileSource;
  profile: OCPPChargingProfile;
  hasDetails: boolean;
}

export enum ChargingProfileSource {
  MANUAL = 'manual',
  SMART_CHARGING = 'smart-charging',
  STATIC_LIMITATION = 'static-limitation',
  UNKNOWN = 'unknown',
}

export interface OCPPChargingProfile extends TableData  {
  chargingProfileId: number;
  transactionId?: number;
  stackLevel: number;
  chargingProfilePurpose: OCPPChargingProfilePurpose;
  chargingProfileKind: OCPPChargingProfileKind;
  recurrencyKind: OCPPChargingProfileRecurrencyKind;
  validFrom?: Date;
  validTo?: Date;
  chargingSchedule: OCPPChargingSchedule;
}

export interface OCPPChargingSchedule {
  duration?: number;
  startSchedule?: Date;
  chargingRateUnit: OCPPChargingRateUnit;
  chargingSchedulePeriod: OCPPChargingSchedulePeriod[];
  minChargeRate?: number;
}

export interface OCPPChargingSchedulePeriod {
  startPeriod: number;
  limit: number;
  numberPhases?: number;
}

export enum OCPPChargingProfileKind {
  ABSOLUTE = 'Absolute',
  RECURRING = 'Recurring',
  RELATIVE = 'Relative',
}

export enum OCPPChargingProfilePurpose {
  CHARGE_POINT_MAX_PROFILE = 'ChargePointMaxProfile',
  TX_DEFAULT_PROFILE = 'TxDefaultProfile',
  TX_PROFILE = 'TxProfile',
}

export enum OCPPGetCompositeScheduleStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export interface GetCompositeScheduleCommandResult {
  status: OCPPGetCompositeScheduleStatus;
  connectorId?: number;
  evseId?: number;
  scheduleStart?: Date;
  chargingSchedule: OCPPChargingSchedule;
}


export enum OCPPChargingProfileRecurrencyKind {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
}

export interface TableChargingProfileSchedule extends TableData {
  id: number;
  startDate: Date;
  endDate?: Date;
  duration: number;
  limit: number;
  limitAmps: number;
  limitWatts: number;
}

export enum ChargingProfileButtonAction {
  VIEW_EXTENDED_CHARGING_PROFILE = 'view_extended_charging_profile',
  VIEW_CHARGING_PROFILE = 'view_charging_profile',
  CREATE_CHARGING_PROFILE = 'create_charging_profile',
  EDIT_CHARGING_PROFILE = 'edit_charging_profile',
  DELETE_CHARGING_PROFILE = 'delete_charging_profile',
  NAVIGATE_TO_CHARGING_PROFILE = 'navigate_to_charging_profile',
  CHECK_SMART_CHARGING_PROFILES = 'check_smart_charging_profiles',
}
