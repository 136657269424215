import { ChargingStationAuthorizationActions, ConnectorAuthorizationActions } from './Authorization';
import { Company } from './Company';
import { KeyValue } from './GlobalType';
import { OCPPChargingRateUnit } from './ocpp/OCPPCommon';
import { Site } from './Site';
import { SiteArea } from './SiteArea';
import { TableData } from './Table';
import { InactivityStatus } from './Transaction';
import { User } from './User';

export interface ChargingStation extends TableData, ChargingStationAuthorizationActions {
  id: string;
  templateHash?: string;
  templateHashTechnical?: string;
  issuer: boolean;
  public: boolean;
  chargePointSerialNumber: string;
  chargePointModel: string;
  chargeBoxSerialNumber: string;
  chargePointVendor: string;
  iccid: string;
  imsi: string;
  meterType: string;
  firmwareVersion: string;
  firmwareUpdateStatus?: OCPPFirmwareStatus;
  diagnosticsUpdateStatus?: OCPPDiagnosticsStatus;
  meterSerialNumber: string;
  endpoint: string;
  ocppVersion: OCPPVersion;
  ocppProtocol: OCPPProtocol;
  lastSeen: Date;
  deleted: boolean;
  inactive: boolean;
  forceInactive: boolean;
  manualConfiguration?: boolean;
  lastReboot: Date;
  lastRebootReason: string;
  chargingStationURL: string;
  masterSlave: boolean;
  maximumPower: number;
  voltage: Voltage;
  excludeFromSmartCharging?: boolean;
  powerLimitUnit: OCPPChargingRateUnit;
  coordinates: number[];
  chargePoints: ChargePoint[];
  connectors: Connector[];
  currentIPAddress?: string;
  siteAreaID?: string;
  siteArea?: SiteArea;
  siteID?: string;
  site?: Site;
  companyID?: string;
  company?: Company;
  ocppStandardParameters?: KeyValue[];
  ocppVendorParameters?: KeyValue[];
  distanceMeters?: number;
  tariffID?: string;
  currentType?: CurrentType[];
  currentLimitWatts?: number;
  reservationID?: number;
}

export enum OCPPProtocol {
  SOAP = 'soap',
  JSON = 'json',
}

export enum OCPPVersion {
  VERSION_15 = '1.5',
  VERSION_16 = '1.6',
  VERSION_201 = '2.0.1',
}

export enum OCPPConfigurationStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  REBOOT_REQUIRED = 'RebootRequired',
  NOT_SUPPORTED = 'NotSupported',
}

export enum OCPPGeneralResponse {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export enum ChargingStationTechnicalProperty {
  CURRENT_TYPE = 'currentType',
  VOLTAGE = 'voltage',
  POWER = 'power',
  AMPERAGE = 'amperage',
  NUMBER_OF_PHASE = 'numberOfConnectedPhase',
}

export interface ChargingStationPowers {
  notSupported: boolean;
  minAmps?: number;
  minWatts?: number;
  maxAmps?: number;
  maxWatts?: number;
  currentAmps?: number;
  currentWatts?: number;
}

export interface OcppCommand {
  command: string;
  parameters: string[];
}

export interface ChargePoint {
  chargePointID: number;
  currentType: CurrentType;
  voltage: Voltage;
  amperage: number;
  numberOfConnectedPhase: number;
  cannotChargeInParallel: boolean;
  sharePowerToAllConnectors: boolean;
  power: number;
  efficiency: number;
  connectorIDs: number[];
  evseID: number;
  ampCurrentLimit?: number;
}

export interface Connector extends TableData, ConnectorAuthorizationActions {
  connectorId: number;
  evseId: number;
  currentInstantWatts: number;
  currentStateOfCharge?: number;
  currentTotalConsumptionWh?: number;
  currentTotalInactivitySecs?: number;
  currentInactivityStatus?: InactivityStatus;
  currentTransactionID?: number;
  currentTransactionDate?: Date;
  currentTagID?: string;
  currentUserID?: string;
  user?: User;
  status: OCPPConnectorStatus;
  errorCode?: string;
  info?: string;
  vendorErrorCode?: string;
  power: number;
  type: ConnectorType;
  voltage?: Voltage;
  amperage?: number;
  statusLastChangedOn?: Date;
  numberOfConnectedPhase?: number;
  currentType?: CurrentType;
  chargePointID?: number;
  hasDetails: boolean;
  isStopAuthorized: boolean;
  isStartAuthorized: boolean;
  phaseAssignmentToGrid: PhaseAssignmentToGrid;
  tariffID?: string;
  reservationID?: number;
}

export interface PhaseAssignmentToGrid {
  csPhaseL1: OCPPPhase.L1 | OCPPPhase.L2 | OCPPPhase.L3;
  csPhaseL2: OCPPPhase.L1 | OCPPPhase.L2 | OCPPPhase.L3;
  csPhaseL3: OCPPPhase.L1 | OCPPPhase.L2 | OCPPPhase.L3;

}

export enum OCPPPhase {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  N = 'N',
  L1_N = 'L1-N',
  L2_N = 'L2-N',
  L3_N = 'L3-N',
  L1_L2 = 'L1-L2',
  L2_L3 = 'L2-L3',
  L3_L1 = 'L3-L1'
}

export enum Voltage {
  VOLTAGE_230 = 230,
  VOLTAGE_110 = 110,
}

export enum ConnectorType {
  TYPE_2 = 'T2',
  COMBO_CCS = 'CCS',
  CHADEMO = 'C',
  TYPE_1 = 'T1',
  TYPE_1_CCS = 'T1CCS',
  DOMESTIC = 'D',
  UNKNOWN = 'U',
}

export enum SiteAreaLimitSource {
  CHARGING_STATIONS = 'CS',
  SITE_AREA = 'SA',
}

export enum ConnectorCurrentLimitSource {
  CHARGING_PROFILE = 'CP',
  STATIC_LIMITATION = 'SL',
  CONNECTOR = 'CO'
}

export enum CurrentType {
  AC = 'AC',
  DC = 'DC',
}

export enum ChargingStationButtonAction {
  REBOOT = 'reboot',
  SOFT_RESET = 'soft_reset',
  CLEAR_CACHE = 'clear_cache',
  RESERVE_NOW = 'reserve_now',
  CANCEL_RESERVATION = 'cancel_reservation',
  GET_LOCAL_LIST_VERSION = 'get_local_list_version',
  SEND_LOCAL_LIST = 'send_local_list',
  START_TRANSACTION = 'start_transaction',
  STOP_TRANSACTION = 'stop_transaction',
  FORCE_STOP_TRANSACTION = 'force_stop_transaction',
  UNLOCK_CONNECTOR = 'unlock_connector',
  EDIT_CHARGING_STATION = 'edit_charging_station',
  VIEW_CHARGING_STATION = 'view_charging_station',
  DELETE_CHARGING_STATION = 'delete_charging_station',
  EXPORT_CHARGING_STATIONS = 'export_charging_stations',
  EXPORT_OCPP_CONFIGURATION = 'export_ocpp_configuration',
  APPLY_TEMPLATE = 'apply_template',
  REQUEST_OCPP_CONFIGURATION = 'request_ocpp_configuration',
  FORCE_AVAILABLE_STATUS = 'force_available_status',
  FORCE_UNAVAILABLE_STATUS = 'force_unavailable_status',
  SAVE_OCPP_PARAMETER = 'save_ocpp_parameter',
  NAVIGATE_TO_CHARGING_STATION = 'navigate_to_charging_station',
  GENERATE_QR_CODE = 'generate_qr_code',
  DOWNLOAD_QR_CODE = 'download_qr_code',
  TRIGGER_MESSAGE = 'trigger_message',
  LIST_CERTIFICATES = 'list_certificates',
}

export enum PlanningButtonAction {
  DELETE_PLANNING = 'delete_planning',
}

export enum OCPPConnectorStatus {
  AVAILABLE = 'Available',
  OCCUPIED = 'Occupied',
  CHARGING = 'Charging',
  FAULTED = 'Faulted',
  RESERVED = 'Reserved',
  FINISHING = 'Finishing',
  PREPARING = 'Preparing',
  SUSPENDED_EVSE = 'SuspendedEVSE',
  SUSPENDED_EV = 'SuspendedEV',
  UNAVAILABLE = 'Unavailable',
}

export enum OCPPDiagnosticsStatus {
  IDLE = 'Idle',
  UPLOADED = 'Uploaded',
  UPLOAD_FAILED = 'UploadFailed',
  UPLOADING = 'Uploading'
}

export enum OCPPFirmwareStatus {
  DOWNLOADED = 'Downloaded',
  DOWNLOAD_FAILED = 'DownloadFailed',
  DOWNLOADING = 'Downloading',
  IDLE = 'Idle',
  INSTALLATION_FAILED = 'InstallationFailed',
  INSTALLING = 'Installing',
  INSTALLED = 'Installed',
}

export enum OCPPAvailabilityType {
  INOPERATIVE = 'Inoperative',
  OPERATIVE = 'Operative',
}

export enum StaticLimitAmps {
  MIN_LIMIT_PER_PHASE = 0, // Do not limit
}
